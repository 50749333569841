import React from 'react'

import { Layout } from '../../components'

import 'animate.css/animate.min.css'

export default props => {
  const { pageContext: { data: page } } = props

  return (
        <Layout
        title={page && page.title}

        description={page && page.description}

        metaTags={page && page.metaTags}

        blocks={page && page.blocks}

        seo_image={page && page.seo_image} />
  )
}